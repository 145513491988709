import React from "react"
import { graphql } from 'gatsby';
import { Subject } from "rxjs";
import NavMenu from '../common/components/Nav/Menu/Menu';
import SEO from '../core/components/SEO/seo';
import ResourceHeading from '../core/components/ResourceHeading/ResourceHeading';
import Slider from '../common/components/Slider/Slider';
import ResourceList from '../core/components/ResourceList/ResourceList';
import { IMenuItem } from '../common/components/Nav/Item';
import { ICard } from '../common/components/Card/Card';
import { ISlide } from '../common/models/slide';
import { Constants } from "../common/constants";

interface CategoryPage {
  data: {
    allWordpressWpResources: {
      nodes: {
        id: string;
        wordpress_id: number;
        slug: string;
        journey: array;
        acf: {
          resource_title: string;
          resource_subtitle: string;
          resource_logo: {
            source_url: string;
          }
        }
      }[];
    }
    wordpressWpJourney: {
      title: string;
      name: string;
      slug: string;
      wordpress_id: string;
      description: string;
      subheader: string;
      acf: {
        background_image: {
          source_url: string;
        }
        quote_text: string;
        attribution: string;
      }
      yoast_meta: {
        yoast_wpseo_title: string;
        yoast_wpseo_metadesc: string;
        yoast_wpseo_facebook_image: string;
        yoast_wpseo_facebook_description: string;
        yoast_wpseo_facebook_title: string;
        yoast_wpseo_twitter_title: string;
        yoast_wpseo_twitter_description: string;
        yoast_wpseo_twitter_image: string;
      }
    }
    allWordpressWpJourney: {
      nodes: {
        name: string;
        slug: string;
        wordpress_id: string;
      }[]
    }
    wordpressPage: {
      title: string;
      acf: {
        headline: string;
        all_resources_headline: string;
        all_resources_description: string;
        slider: {
          description: string;
          location: string;
          name: string;
          store: string;
          background_image: {
            source_url: string;
          }
        }[];
      }
    }
  }
}

const Category = (props: CategoryPage) => {
  const data = props.data;
  const page = data.wordpressWpJourney;
  const acf = data.wordpressPage.acf;
  const resources = data.allWordpressWpResources.nodes
    .map(x => {
      const resource: ICard = {
        imageUrl: x.acf.resource_logo ? x.acf.resource_logo.source_url : "",
        linkUrl: `${Constants.Paths.resources}${x.slug}`,
        title: x.acf.resource_subtitle,
        subtitle: x.acf.resource_title,
        buttonEvent: new Subject<void>()
      }

      return resource;
    });

  const slidesOne = acf.slider
    .map(x => {
      const slide: ISlide = {
        quote: x.description,
        address2: x.location,
        author: x.name,
        address1: x.store,
        backgroundImage: x.background_image.source_url
      }
      return slide;
    });

  const slideTwo : ISlide = {
    quote: page.acf !== null ? page.acf.quote_text : '',
    address2: '',
    author: '',
    address1: page.acf !== null ? page.acf.attribution : '',
    backgroundImage: page.acf !== null ? page.acf.background_image ? page.acf.background_image.source_url : '' : ''
  };

  const slidesTwo = new Array;

  slidesTwo.push( slideTwo );

  const displaySlides = page.acf !== null ? page.acf.background_image ? slidesTwo : slidesOne : slidesOne;

  const noEmptySlider =  false;

  return (
    <>
      <SEO
        title={`${data.wordpressWpJourney.name} - My Walmart Cares`}
        description={data.wordpressWpJourney.description}
      />
      <NavMenu items={props.data.allWordpressWpJourney.nodes.map(x => {
        const menuItem: IMenuItem = {
          url: `${Constants.Paths.categories}${x.slug}`,
          name: x.name
        }
        return menuItem;
      })} />
      <ResourceHeading title='Walmart Cares Resources' />
      {noEmptySlider &&
        <Slider slides={displaySlides} />
      }
      <ResourceList
        list={resources}
        subheader={page.name}
        description={page.description}
        backToHome={true}
      />
    </>
  )
};

export default Category;
export const query = graphql`
  query ($id: Int!) {
    allWordpressWpResources (filter: {journey: {eq: $id}}) {
      nodes {
        id
        wordpress_id
        slug
        journey
        acf {
          resource_title
          resource_subtitle
          resource_logo {
            source_url
          }
        }
      }
    }
    allWordpressWpJourney {
      nodes {
        name
        slug
      }
    }
    wordpressPage(wordpress_id: { eq: 111 }) {
      title
      acf {
        headline
        all_resources_headline
        all_resources_description
        slider {
          description
          name
          store
          location
          background_image {
            source_url
          }
        }
      }
    }
    wordpressWpJourney (wordpress_id: {eq: $id}) {
      name
      slug
      description
      wordpress_id
      subheader
      acf {
        quote_text
        attribution
      }
    }
  }
`;
